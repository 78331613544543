<template>
  <div>
    <b-row class="match-height">
      <b-col>
        <h2 class="font-medium-3">
          Lịch
        </h2>
      </b-col>
      <b-col
        lg="2"
        sm="6"
        md="3"
      >
        <select-apartment
          v-model="apartment"
          :enable-default-select="true"
        />
      </b-col>
    </b-row>
    <div class="app-calendar overflow-hidden border">

      <div class="row no-gutters">

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue';
import FullCalendar from '@fullcalendar/vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import calendarStoreModule from './calendarStoreModule';
import useCalendar from './useCalendar';
import SelectApartment from '../components/SelectApartment.vue';

export default {
  components: {
    FullCalendar,
    BRow,
    BCol,
    SelectApartment,
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar';

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
    });

    const {
      refCalendar,
      calendarOptions,
      apartment,
    } = useCalendar();

    return {
      refCalendar,
      apartment,
      calendarOptions,

    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
