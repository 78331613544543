import store from '@/store';
import viLocale from '@fullcalendar/core/locales/vi';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { ref, watch } from '@vue/composition-api';
import toast from '@/utils/toast';

export default function userCalendar() {
  const refCalendar = ref(null);

  const apartment = ref(null);
  const toastification = toast();

  const calendarOptions = ref({
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    plugins: [resourceTimelinePlugin],
    initialView: 'resourceTimelineMonth',
    resourceAreaHeaderContent: 'Phòng',
    // resourceGroupField: 'building',

    headerToolbar: {
      start: 'prev,next, title',
      end: 'resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear',
    },

    resourceAreaWidth: 200,

    columnHeaderText: apartment.value ? apartment.value.name : 'Tòa nhà',

    // Locale
    locale: viLocale,

    resources: [],
    events: [],

    eventResize({ event: resizedEvent }) {

    },
    eventClassNames({ event: calendarEvent }) {
      return [
        `bg-light-primary`,
      ];
    },
    eventClick({ event: clickedEvent }) {

    },
  });

  const fetchApartmentCalendar = (apartmentValue, range) => {
    if (!apartmentValue) {
      return;
    }
    store
      .dispatch('apartment/fetchCalendar', { apartmentId: apartmentValue.id })
      .then(response => {
        const { resources, events } = response.data;

        calendarOptions.value.events = events;
        calendarOptions.value.resources = resources;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  watch(apartment, val => {
    calendarOptions.value.resourceAreaHeaderContent = val ? val.name : 'Tòa nhà';

    fetchApartmentCalendar(val, refCalendar.value.getApi().currentData.dateProfile.activeRange);
  });

  return {
    apartment,
    refCalendar,
    calendarOptions,

  };
}
